import { debounce } from "lodash";
import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import home from "./modules/home";
import back from "./modules/back";
import guidance from "./modules/guidance";
import { LANGUAGES_KEY } from "@/config";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    lang: "en",
    langList: [
      {
        key: "en",
        path: "en",
        na: "English",
        antd: "en_US",
        moment: "en",
        api: "en-US",
      },
      {
        key: "zh_CN",
        path: "zh_CN",
        na: "简体中文",
        antd: "zh_CN",
        moment: "zh-cn",
        api: "zh-CN",
      },
    ],
    // 表格铺满屏幕且页面仅有面包屑时
    // 如果有其它内容接着减去即可
    tableFullScrollY: window.innerHeight - 287,
  },
  modules: {
    user,
    home,
    back,
    guidance,
  },
  mutations: {
    setLang(state, { value, isInit }) {
      if (state.langList.some((it) => it.key === value)) {
        // 切换语言时重新设置cookie并刷新页面
        if (value !== state.lang && !isInit) {
          state.lang = value;
          localStorage.setItem(LANGUAGES_KEY, value);
          const delay = debounce(() => {
            window.location.reload();
          }, 300);
          delay();
          return;
        }
        state.lang = value;
      }
    },
  },
  getters: {
    // 获取当前所选语言的全部字段
    currentLang(state) {
      const curr = state.langList.find((it) => it.key === state.lang);
      if (!curr) return {};
      return { ...curr };
    },

    currentLanguage() {
      return store.state.lang;
    },

    isChinese() {
      if (!store.state.lang) {
        return true; //默认中文
      }

      if (store.state.lang.toLowerCase().startsWith("zh")) {
        return true;
      }

      return false;
    },
  },
});

export default store;
