import api from "@/utils/fetch";

/**
 * 获取公司余额
 * @param params
 * @param {string} params.companyId
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function getCompanyPoints(params) {
  return api.get("/company/points/get", params);
}
// 首页卡片数据
export function getStatistics(params) {
  return api.get("/company/home/platform/statistics/get", params);
}

// HR成长记录
export function getHRNotes(params) {
  return api.get("/user/points/notes", params);
}

// 发送导出验证码
export function exportSendCode(params) {
  return api.post("/rc/order/list-page/export/send-code", params);
}

//获取候选人资料
export function getCandidateFiles(params) {
  return api.get("/commonMgt/candidateFiles", params);
}

//获取报告详情
export function taskModuleRecord(params) {
  return api.get("/commonMgt/taskModuleRecord", params);
}

// 获取客服信息
export function customerService(params) {
  return api.get("/company/customerService", params);
}

// 提交任务单变更(撤销核实)
export function changeTask(params) {
  return api.post("/entrust/changeTask", params);
}

// 获取智薪登录地址
export function getSmartSalary(params) {
  return api.post("/eco/smart-salary/get-login-url", params);
}
