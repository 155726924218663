// 首页容器
const Home = () => {
  return import(/* webpackChunkName: "home" */ "@/pages/home/Index.vue");
};

// 欢迎
const HomePage = () => {
  return import(/* webpackChunkName: "home" */ "@/pages/home/Home.vue");
};

// 首页
const Index = () => {
  return import(
    /* webpackChunkName: "home" */ "@/pages/home/dashboard/Index.vue"
  );
};

// 审核妙计列表
const auditList = () => {
  return import(/* webpackChunkName: "home" */ "@/pages/auditTricks/Index.vue");
};

//#region 背景调查
// 工作台 - 背景调查
const Dashboard = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/workspace/backgroundCheck/index/list.vue"
  );
};

// 新建委托订单（新：2023.6）
const BackOrderAdd = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/workspace/backgroundCheck/order/Index.vue"
  );
};

// 新建委托订单-选择类型（新：2023.6）
const BackOrderAddEntry = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/workspace/backgroundCheck/order/Home.vue"
  );
};

// 新建委托订单-选择模板（新：2023.6）
const BackOrderAddTemplate = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/workspace/backgroundCheck/order/Template.vue"
  );
};

// 新建委托订单-录入候选人信息（新：2023.6）
const BackOrderAddForm = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/workspace/backgroundCheck/order/Form.vue"
  );
};

// 新建委托订单-操作结果（新：2023.6）
const BackOrderAddResult = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/workspace/backgroundCheck/order/Result.vue"
  );
};

// 候选人管理
const CddManage = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/home/CanManage/List.vue"
  );
};

// 报告详情
const OrderReport = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/home/order/Report.vue"
  );
};

// 查看背调报告
const RcOrderReport = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/home/order/RcReport.vue"
  );
};

// 消息管理列表
const MessageList = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/home/message/List.vue"
  );
};

// 任务进度
const MessageProcess = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/home/message/ReportProcess.vue"
  );
};

// 二维码授权列表
const QrCodeAuthList = () => {
  return import(
    /* webpackChunkName: "background" */ "@/pages/workspace/backgroundCheck/qrCodeAuth/Index.vue"
  );
};
//#endregion

//#region 福利平台
// 工作台 - 福利平台
const WelfarePlatIndex = () => {
  return import(
    /* webpackChunkName: "welfare" */ "@/pages/workspace/welfarePlatform/index.vue"
  );
};
//#endregion

//#region 人事管理
const hrManagement = () => {
  return import(/* webpackChunkName: "hr" */ "@/pages/HR/index.vue");
};
//#endregion

//#region 资源中心
// 资源中心
const ResourceList = () => {
  return import(
    /* webpackChunkName: "resource" */ "@/pages/resource/Index.vue"
  );
};
//#endregion

//#region 会员中心
const MemberList = () => {
  return import(/* webpackChunkName: "member" */ "@/pages/member/Index.vue");
};
//#endregion

//#region 商城管理
// 商品管理-列表
const ProductList = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/product/Index.vue");
};

// 商品管理-列表-编辑
const ProductEdit = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/product/Edit.vue");
};

//商品管理-分类
const Productcategory = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/product/category/Index.vue"
  );
};

//商品管理-分类-编辑
const ProductcategoryEdit = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/product/category/Edit.vue"
  );
};

// 商品管理-规格
const ProductSpecList = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/product/spec/Index.vue"
  );
};

// 商品管理-规格-编辑
const ProductSpecEdit = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/product/spec/Edit.vue");
};

// 商品管理-促销标签
const promotionalLabel = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/product/promotionalLabel/Index.vue"
  );
};

// 商品管理-促销标签-编辑
const promotionalLabelEdit = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/product/promotionalLabel/Edit.vue"
  );
};

// 供应商管理-品牌列表
const ProductBrandList = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/supplier/brand/Index.vue"
  );
};

// 供应商管理-品牌列表-编辑
const ProductBrandEdit = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/supplier/brand/Edit.vue"
  );
};

// 供应商管理-供应商列表
const SupplierList = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/supplier/Index.vue");
};

// 供应商管理-供应商列表-编辑
const SupplierEdit = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/supplier/Edit.vue");
};

// 供应商管理-优惠券管理
const CouponList = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/supplier/coupon/Index.vue"
  );
};

// 供应商管理-供应商列表-编辑
const CouponEdit = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/supplier/coupon/Edit.vue"
  );
};

// 订单管理
const OrderList = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/order/Index.vue");
};

const OrderEdit = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/order/Edit.vue");
};

// 售后
const AfterSales = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/order/afterSales/Index.vue"
  );
};

// 快递公司
const ExpressList = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/express/Index.vue");
};

// 快递资费
const ExpressCostList = () => {
  return import(
    /* webpackChunkName: "shop" */ "@/pages/express/cost/Index.vue"
  );
};
// 添加快递资费
const ExpressCostAdd = () => {
  return import(/* webpackChunkName: "shop" */ "@/pages/express/cost/Edit.vue");
};

//#endregion

//#region 系统管理
// 数据字典
const DicList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/dic/Index.vue"
  );
};

// 菜单管理
const MenuList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/menu/Index.vue"
  );
};

// 角色管理
const RoleList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/role/Index.vue"
  );
};

// 用户管理
const UserList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/user/Index.vue"
  );
};

// 组织架构
const OrgList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/org/Index.vue"
  );
};

// 系统配置
const ConfigList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/conf/Index.vue"
  );
};

// 兑换企业积分比例
const RatioList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/ratio/Index.vue"
  );
};

// 公司管理
// const CompanyList = () => {
//   return import(
//     /* webpackChunkName: "system" */ "@/pages/system/company/Index.vue"
//   );
// };

// const CompanyEdit = () => {
//   return import(
//     /* webpackChunkName: "system" */ "@/pages/system/company/Form.vue"
//   );
// };

// banner管理
const BannerList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/banner/Index.vue"
  );
};

const SysBenefitsList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/system/benefits/Index.vue"
  );
};
//#endregion

//#region 入职礼
const GiftList = () => {
  return import(
    /* webpackChunkName: "system" */ "@/pages/workspace/backgroundCheck/gift/Index.vue"
  );
};
//#endregion

//#region 调查问卷
const SurveyList = () => {
  return import(
    /* webpackChunkName: "survey" */ "@/pages/workspace/survey/Index.vue"
  );
};
//#endregion

export default {
  path: "/",
  component: Home,
  children: [
    //#region 欢迎
    // {
    //   path: "/",
    //   name: "HomePage",
    //   component: HomePage,
    //   meta: {
    //     title: "欢迎"
    //   }
    // },
    //#endregion
    //#region 首页
    // {
    //   path: "/",
    //   name: "Index",
    //   component: Index,
    //   meta: {
    //     title: "首页",
    //     titleEn: "Home",
    //   }
    // },
    {
      path: "/",
      name: "Index",
      component: Dashboard,
      meta: {
        title: "背景调查",
        titleEn: "Background Check",
      },
    },
    {
      path: "/audit-tricks",
      name: "auditList",
      component: auditList,
      meta: {
        title: "小妙计",
        titleEn: "Smart Tips",
        menu: "/",
      },
    },
    //#endregion
    //#region 背景调查
    // 工作台管理-背景调查
    {
      path: "/workspace/background",
      name: "BackCheck",
      component: Dashboard,
      meta: {
        title: "背景调查",
        titleEn: "Background Check",
      },
    },
    {
      path: "/workspace/background/qr-code-auth",
      component: QrCodeAuthList,
      name: "QrCodeAuthList",
      meta: {
        title: "二维码授权列表",
        titleEn: "QrCode Authorization List",
        menu: "/workspace/background",
      },
    },
    // 新建委托订单（新：2023.6）
    {
      path: "/workspace/background/add",
      component: BackOrderAdd,
      meta: {
        title: "新建委托",
        titleEn: "New Commission",
        menu: "/workspace/background",
      },
      children: [
        {
          path: "/",
          component: BackOrderAddEntry,
          name: "BackOrderAdd",
          meta: {
            title: "添加方式",
            titleEn: "Add Mode",
            menu: "/workspace/background",
          },
        },
        {
          path: ":type/template",
          component: BackOrderAddTemplate,
          name: "BackOrderAddTemplate",
          meta: {
            title: "模板选择",
            titleEn: "Template Selection",
            menu: "/workspace/background",
          },
        },
        {
          path: ":type/form",
          component: BackOrderAddForm,
          name: "BackOrderAddForm",
          meta: {
            title: "信息完善",
            titleEn: "Information Completion",
            menu: "/workspace/background",
          },
        },
        {
          path: ":type/result",
          component: BackOrderAddResult,
          name: "BackOrderAddResult",
          meta: {
            title: "确认完成",
            titleEn: "Confirm Completion",
            menu: "/workspace/background",
          },
        },
      ],
    },
    // 候选人管理
    {
      path: "/workspace/background/candidate-manage",
      component: CddManage,
      name: "CddManage",
      meta: {
        title: "候选人管理",
        menu: "/workspace/background",
      },
    },
    // 候选人报告详情
    {
      path: "/workspace/background/candidate-report/:id/:status",
      component: OrderReport,
      name: "OrderReport",
      meta: {
        title: "报告详情",
        menu: "/workspace/background",
      },
    },
    //查看背调报告
    {
      path: "/workspace/background/candidate/rc-report/:id",
      component: RcOrderReport,
      name: "RcOrderReport",
      meta: {
        title: "报告详情",
        menu: "/workspace/background",
      },
    },
    {
      path: "/message/list",
      component: MessageList,
      meta: {
        title: "消息列表",
        menu: "/workspace/background",
      },
    },
    {
      path: "/message/schedule/:id",
      name: "MessageProcess",
      component: MessageProcess,
      meta: {
        title: "任务进度",
        menu: "/workspace/background",
      },
    },
    //#endregion
    //#region 福利平台
    // 工作台管理-福利平台
    {
      path: "/workspace/welfare",
      name: "WelfarePlatIndex",
      component: WelfarePlatIndex,
      meta: {
        title: "福利平台",
      },
    },
    // 新建委托
    {
      path: "/workspace/welfare/gift",
      component: GiftList,
      name: "GiftList",
      meta: {
        title: "入职礼发放记录",
        menu: "/workspace/welfare",
      },
    },
    //#endregion
    //#region 调查问卷
    {
      path: "/workspace/survey",
      component: SurveyList,
      name: "SurveyList",
      meta: {
        title: "问卷明细",
      },
    },
    //#endregion
    //#region 人事管理
    {
      path: "/hr-management",
      name: "hrManagement",
      component: hrManagement,
      meta: {
        title: "人事管理",
      },
    },
    //#endregion
    //#region 资源中心
    {
      path: "/resource-center",
      name: "ResourceList",
      component: ResourceList,
      meta: {
        title: "资源中心",
      },
    },
    //#endregion
    //#region 会员中心
    {
      path: "/member-center",
      name: "MemberList",
      component: MemberList,
      meta: {
        title: "会员中心",
      },
    },
    //#endregion
    //#region 商城管理
    {
      path: "/product-list",
      name: "ProductList",
      component: ProductList,
      meta: {
        title: "商品列表",
      },
    },
    {
      path: "/product-list/add",
      name: "ProductAdd",
      component: ProductEdit,
      meta: {
        title: "添加商品",
        menu: "/product-list",
      },
    },
    {
      path: "/product-list/edit/:productNo?",
      name: "ProductEdit",
      component: ProductEdit,
      meta: {
        title: "修改商品",
        menu: "/product-list",
      },
    },
    {
      path: "/product-category",
      name: "Productcategory",
      component: Productcategory,
      meta: {
        title: "商品分类",
      },
    },
    {
      path: "/product-category/add",
      name: "ProductcategoryAdd",
      component: ProductcategoryEdit,
      meta: {
        title: "添加分类",
        menu: "/product-category",
      },
    },
    {
      path: "/product-category/edit/:id?",
      name: "ProductcategoryEdit",
      component: ProductcategoryEdit,
      meta: {
        title: "修改分类",
        menu: "/product-category",
      },
    },
    {
      path: "/product-spec",
      name: "ProductSpecList",
      component: ProductSpecList,
      meta: {
        title: "商品规格",
      },
    },
    {
      path: "/product-spec/add",
      name: "ProductSpecAdd",
      component: ProductSpecEdit,
      meta: {
        title: "添加规格",
        menu: "/product-spec",
      },
    },
    {
      path: "/product-spec/edit/:id?",
      name: "ProductSpecEdit",
      component: ProductSpecEdit,
      meta: {
        title: "修改规格",
        menu: "/product-spec",
      },
    },
    {
      path: "/product-label",
      name: "promotionalLabel",
      component: promotionalLabel,
      meta: {
        title: "促销标签",
      },
    },
    {
      path: "/product-label/add",
      name: "promotionalLabelAdd",
      component: promotionalLabelEdit,
      meta: {
        title: "添加标签",
        menu: "/product-label",
      },
    },
    {
      path: "/product-label/edit/:id?",
      name: "promotionalLabelEdit",
      component: promotionalLabelEdit,
      meta: {
        title: "修改标签",
        menu: "/product-label",
      },
    },
    {
      path: "/product-brand",
      name: "ProductBrandList",
      component: ProductBrandList,
      meta: {
        title: "品牌管理",
      },
    },
    {
      path: "/product-brand/add",
      name: "ProductBrandAdd",
      component: ProductBrandEdit,
      meta: {
        title: "添加品牌",
        menu: "/product-brand",
      },
    },
    {
      path: "/product-brand/edit/:id?",
      name: "ProductBrandEdit",
      component: ProductBrandEdit,
      meta: {
        title: "修改品牌",
        menu: "/product-brand",
      },
    },
    {
      path: "/supplier-list",
      name: "SupplierList",
      component: SupplierList,
      meta: {
        title: "供应商管理",
      },
    },
    {
      path: "/supplier-list/add",
      name: "SupplierAdd",
      component: SupplierEdit,
      meta: {
        title: "添加供应商",
        menu: "/supplier-list",
      },
    },
    {
      path: "/supplier-list/edit/:id?",
      name: "SupplierEdit",
      component: SupplierEdit,
      meta: {
        title: "修改供应商",
        menu: "/supplier-list",
      },
    },
    {
      path: "/coupon-list",
      name: "CouponList",
      component: CouponList,
      meta: {
        title: "优惠券管理",
      },
    },
    {
      path: "/coupon-list/add",
      name: "CouponAdd",
      component: CouponEdit,
      meta: {
        title: "添加优惠券",
        menu: "/coupon-list",
      },
    },
    {
      path: "/coupon-list/edit/:id?",
      name: "CouponEdit",
      component: CouponEdit,
      meta: {
        title: "修改优惠券",
        menu: "/coupon-list",
      },
    },
    {
      path: "/order-list",
      name: "OrderList",
      component: OrderList,
      meta: {
        title: "订单管理",
      },
    },
    {
      path: "/after-sales",
      name: "AfterSales",
      component: AfterSales,
      meta: {
        title: "售后管理",
      },
    },
    {
      path: "/order-list/edit/:orderId?",
      name: "OrderDetail",
      component: OrderEdit,
      meta: {
        title: "订单详情",
        menu: "/order-list",
      },
    },
    {
      path: "/express/list",
      name: "ExpressList",
      component: ExpressList,
      meta: {
        title: "快递公司",
      },
    },
    {
      path: "/express/cost/list",
      name: "ExpressCostList",
      component: ExpressCostList,
      meta: {
        title: "快递资费",
      },
    },
    {
      path: "/express/cost/list/add",
      name: "ExpressCostAdd",
      component: ExpressCostAdd,
      meta: {
        title: "添加快递资费",
        menu: "/express/cost/list",
      },
    },
    {
      path: "/express/cost/list/edit/:id",
      name: "ExpressCostEdit",
      component: ExpressCostAdd,
      meta: {
        title: "修改快递资费",
        menu: "/express/cost/list",
      },
    },
    //#endregion
    //#region 系统管理
    {
      path: "/system/dic/list",
      name: "DicList",
      component: DicList,
      meta: {
        title: "数据字典",
      },
    },
    {
      path: "/system/menu/list",
      name: "MenuList",
      component: MenuList,
      meta: {
        title: "菜单管理",
      },
    },
    {
      path: "/system/role/list",
      name: "RoleList",
      component: RoleList,
      meta: {
        title: "角色管理",
      },
    },
    {
      path: "/system/user/list",
      name: "UserList",
      component: UserList,
      meta: {
        title: "用户管理",
      },
    },
    {
      path: "/system/org/list",
      name: "OrgList",
      component: OrgList,
      meta: {
        title: "组织架构",
      },
    },
    {
      path: "/system/config/list",
      name: "ConfigList",
      component: ConfigList,
      meta: {
        title: "系统配置",
      },
    },
    {
      path: "/system/exchange/list",
      name: "RatioList",
      component: RatioList,
      meta: {
        title: "企业兑换配置",
      },
    },
    // {
    //   path: "/system/company/list",
    //   name: "CompanyList",
    //   component: CompanyList,
    //   meta: {
    //     title: "公司管理"
    //   }
    // },
    // {
    //   path: "/system/company/add",
    //   name: "CompanyAdd",
    //   component: CompanyEdit,
    //   meta: {
    //     title: "添加公司",
    //     menu: "/system/company/list"
    //   }
    // },
    // {
    //   path: "/system/company/edit/:companyId?",
    //   name: "CompanyEdit",
    //   component: CompanyEdit,
    //   meta: {
    //     title: "修改公司",
    //     menu: "/system/company/list"
    //   }
    // },
    {
      path: "/system/banner/list",
      name: "BannerList",
      component: BannerList,
      meta: {
        title: "Banner管理",
      },
    },
    {
      path: "/system/benefits/list",
      name: "SysBenefitsList",
      component: SysBenefitsList,
      meta: {
        title: "系统福利",
      },
    },
    //#endregion
  ],
};
